// import { Helmet } from "react-helmet";
// import React, { useLayoutEffect } from 'react'

const About = () => {

    // useLayoutEffect(() => {
    //     document.body.style.background = "linear-gradient(to right, rgb(15,23,42), rgb(34, 45, 71))"
    // })

    return ( 
        <section className="note" id="about">
            {/* <Helmet bodyAttributes={{style: 'background-color : linear-gradient(rgb(15,23,42), rgb(15,23,42), rgb(34, 45, 71));'}}/> */}
            <div>
                <br/><br/><br/><br/>
            </div>
            {/* <p>Hello, my name is Rishita Nakum and I'm a back end expert engineer from Canada.  I love to develop performance optimized solutions.  I'm also passionate about learning new technologies.  Portfolio is my wonderful playground where I can gather all creative ideas, play and experiment and bring it to reality.  It's a fun way to learn something new and do something different.</p>
            <br/>
            <p>When I'm not in front of a computer either I'm following delicious recipes, traveling, doing zumba with my kid or watching Netflix with my husband!</p>
            <br/>
            <p>I'm open to new job opportunities where I can make a difference, learn and grow.  If you have a good role and would like to discuss it further then please feel free to contact me!</p> */}

            <p><span className="font-weight-bold fs-4">Hello World!</span><br/>Welcome to my portfolio.  My name is Rishita and I'm a professional software engineer based in North America.  This is my wonderful playground wherein I gather all creative ideas, play and experiment with it and bring it to the reality. It's a fun way to learn something new and do something different.  Throughout in my career, I’ve had the privilege of working with startups and established enterprises alike, contributing to projects that range from web, windows-based applications to cloud based solutions.  I specialize in creating robust, scalable solutions that drive business success.</p>
            <br/>

            <p>When I'm not in front of a computer either I'm following delicious recipes, traveling, doing Zumba with my kid or watching Netflix with my husband!
            </p>
            <br/>
            
            <p>I’m always excited to collaborate on new projects and explore innovative solutions. Whether you’re looking for a consultant, a team member, or simply want to share ideas, feel free to reach out!</p>
            <br/>
            <p className='fw-bold lead'>
                <a href="assets/Portfolio_RishitaNakum_Resume.pdf" target="_blank" style={{color: 'inherit', textDecoration: 'inherit'}}>View Full Resume <i className="bi bi-arrow-up-right fs-6"></i></a>
            </p>
        </section>
     );
}
 
export default About;