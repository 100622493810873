import './App.css';
import { Helmet } from "react-helmet"
import Experience from './Experience';
import Projects from './Projects';
import Certification from './Certification';
import About from './About';
import Navbar from './Navbar';

function App() {
  return (
    <div className="App">    
      <section className="container-xl">
        <div className="row">
            <div className="col-md-1 d-none d-md-block"></div>
            
            <div className="col-md-4 col-sm-12">
                <Navbar></Navbar>
            </div>
            <div className="col-md-1">&nbsp;</div>

            <div className="col-md-6 col-sm-12">
                <About></About>

                <Experience></Experience>

                <Projects></Projects>

                <Certification></Certification>
                
            </div>
            {/* <div className="col-md-1 d-none d-md-block bg-info">&nbsp;</div> */}
            
        </div>
        <div className="row text-center mt-5 fs-6">
          <p className="text-secondary">Designed and developed by Rishita Nakum.  Built using <b>React</b> and <b>Node.js</b>, deployed with <b>Amazon Web Services.</b></p>
        </div>
    </section>
    </div>
  );
}

export default App;
