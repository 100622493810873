const Navbar = () => {
    return ( 
        <div className='nav-main'>
            <br/><br/><br/><br/>
            <a href="https://rishitanakum.com/"><h1 className="fs-1 fw-bold">Rishita Nakum</h1></a>
            <h4 className="mt-2 mb-3">Solution Architect</h4>
            <p className="mb-5">I'm backend expert and would love to build creative solutions which are highly optimized and scalable.</p>
            
            <ul className="nav-list d-none d-md-block">
                <li className="mt-3" style={{width: '200px'}}>
                    <a href="#about" className="nav-link active fs-6 fw-normal">
                        <span className="first">about</span>
                        <span className="second">about</span>
                    </a>
                </li>
                <li className="mt-3" style={{width: '200px'}}>
                    <a href="#experience" className="nav-link fs-6">
                        <span className="first">Experience</span>
                        <span className="second">Experience</span>
                    </a>
                </li>
                <li className="mt-3" style={{width: '200px'}}>
                    <a href="#projects" className="nav-link fs-6 fw-normal">
                        <span className="first">Projects</span>
                        <span className="second">Projects</span>
                    </a>
                </li>
                <li className="mt-3" style={{width: '200px'}}>
                    <a href="#certification" className="nav-link fs-6 fw-normal">
                        <span className="first">Certifications</span>
                        <span className="second">Certifications</span>
                    </a>
                </li>
            </ul>

            <br/><br/>
            <div>
                <img src="assets/rn2.jpg" alt="" className="myImg" style={{ border: '15px solid rgb(226,232,240)' }}/>
                {/* rgb(167,58,183)  style={{ border: '10px solid rgb(167,58,183)' }}           */}
            </div>
            <div className="mt-4">
            <a href="https://www.linkedin.com/in/rishita-nakum/" target="_blank" style={{color: 'inherit'}}><i className="bi bi-linkedin fs-4 pe-4"></i></a>
                <a href="assets/Portfolio_RishitaNakum_Resume.pdf" target="_blank" style={{color: 'inherit'}}><i className="bi bi-file-earmark-pdf fs-4 pe-4"></i></a>                
                <a href="mailto:rs.nakum@gmail.com" style={{color: 'inherit'}}><i className="bi bi-envelope-at fs-4 pe-4"></i></a>
            </div>
        </div>
     );
}
 
export default Navbar;