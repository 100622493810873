import useFetch from "./useFetch";

const Certification = () => {
    const {data: dataset, ispending, error } = useFetch('/api/certification/');
  
    return ( 
        <section className="note" id="certification">
            <div>
                <br/><br/><br/>
            </div>
            <p className="fs-1 fw-semibold">Certifications</p>
            {dataset && dataset.map((data) => (
                <div className="row" key={data._id}>
                    <div className="col-md-3">
                        <img src={process.env.PUBLIC_URL + data.imgPath } className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-9">
                        <span className="fw-bold">{data.title}</span>
                        <p className="text-secondary fw-semibold" style={{width: "fit-content"}}>{data.validThrough}</p>
                    </div>
                    <p><br/></p>                    
                </div>
            ))}

            { error &&
                <span className="lead">Could not fetch the data for the resource</span>
            }

            {/* <div className="row">
                <div className="col-md-3">
                    <img src="assets/AWS-Certified-Solutions-Architect-Associate.png" className="img-fluid" alt="" />
                </div>
                <div className="col-md-9">
                    <span className="fw-bold">AWS Certified Solution Architect Associate</span>
                    <p className="text-secondary fw-semibold" style={{width: "fit-content"}}>2009 - 2017</p>
                </div>
            </div>
            <br/><br/><br/>
            <div className="row">
                <div className="col-md-3">
                    <img src="assets/AWS-Certified-Developer-Associate.png" className="img-fluid" alt="" />
                </div>
                <div className="col-md-9">
                    <span className="fw-bold">AWS Certified Developer Associate</span>
                    <p className="text-secondary fw-semibold" style={{width: "fit-content"}}>2009 - 2017</p>
                </div>
            </div>
            <br/><br/><br/>
            <div className="row">
                <div className="col-md-3">
                    <img src="assets/AWS-Certified-Cloud-Practitioner.png" className="img-fluid" alt="" />
                </div>
                <div className="col-md-9">
                    <span className="fw-bold">AWS-Certified-Cloud-Practitioner</span>
                    <p className="text-secondary fw-semibold" style={{width: "fit-content"}}>2009 - 2017</p>
                </div>
            </div> */}
        </section>
     );
}
 
export default Certification;