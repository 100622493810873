import useFetch from "./useFetch";

const Projects = () => {
    const {data: dataset, ispending, error } = useFetch('/api/project/');
    
    return ( 
        <section className="note" id="projects">
            <div>
                <br/><br/><br/>
            </div>

            <p className="fs-1 fw-semibold">Projects</p>
            {dataset && dataset.map((data) => (
                <div className="row mt-5" key={data._id}>
                    <div className="col-md-3">
                        <a href={data.link} target="_blank" style={{color: 'inherit', textDecoration: 'inherit'}}>
                            <img src={process.env.PUBLIC_URL + data.imgPath } className="img-fluid mt-1" alt="" style={{ border: '2px solid rgb(64, 64, 64)' }}/> 
                        </a>
                    </div>
                    <div className="col-md-9">
                        <span className="fw-bold"><a href={data.link} target="_blank" style={{color: 'inherit', textDecoration: 'inherit'}}>{data.name}</a></span>
                        <p className="mt-2">{data.content}</p>
                        {data.techStack.map((item) => (
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2" key={item._id}>{item.techName}</span>
                        ))}
                    </div>
                </div>
            ))}

            { error &&
                <span className="lead">Could not fetch the data for the resource</span>
            }
            {/* <div className="row mt-5">
                <div className="col-md-3">
                    <img src="assets/AboutStory.jpg" className="img-fluid pt-2" alt="" style={{ border: '2px solid rgb(64, 64, 64)' }}/>
                </div>
                <div className="col-md-9">
                    <span className="fw-bold">RN Restaurant</span>
                    <p className="mt-2">This is a customized and responsive site designed using bootstrap for food lovers to indulge into delicious food taste.  With the practice of implementing features like restaurant menu with chef's special, reserve a table and the amazing food gallery including special effects.  The site is completely dynamic using Node.js as frontend and backend as well as data is driven from NoSql atlas.</p>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Bootstrap</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Node.Js</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">API</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">MongoDB</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">HTML</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">CSS</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">CSS</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">CSS</span>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-3">
                    <img src="assets/AboutStory.jpg" className="img-fluid pt-2" alt="" style={{ border: '2px solid rgb(64, 64, 64)' }}/>
                </div>
                <div className="col-md-9">
                    <span className="fw-bold">RN Spa</span>
                    <p className="mt-2">RN Spa application is the greatest experience of all designing from scratch till developing end to end dynamic features.  It's a complete responsive site with unique content and banners for each page.  The multistep reservation makes a unique experience to navigate between each step and reserves the details as you make progress.   The service and package plan would brief you about our modest packages and beautiful gallery view to prepare you for the wonderful spa experience.</p>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Bootstrap</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">React</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Node.Js</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">API</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">HTML</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">CSS</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">MongoDB</span>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-3">
                    <img src="assets/AboutStory.jpg" className="img-fluid pt-2" alt="" style={{ border: '2px solid rgb(64, 64, 64)' }}/>
                </div>
                <div className="col-md-9">
                    <span className="fw-bold">RN Fitness Center</span>
                    <p className="mt-2">RN Fitness Center is a special customized application with a complex NoSql database collection designed for fitness lovers.  It serves multiple locations across different provinces to browse classes.  The dynamic schedule displays the latest of the current week with class details, instructor, category, duration.  The additional filters like Category, instructor, class intensity, time and weekday are added to further narrow down the result as per the specific need.</p>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Bootstrap</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Angular</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Node.Js</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">HTML</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">CSS</span>
                    <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">MongoDB</span>
                </div>
            </div> */}
        </section>
     );
}
 
export default Projects;