import useFetch from "./useFetch";
import { useRef, useEffect } from "react";

const Experience = () => {
    const {data: dataset, ispending, error } = useFetch('/api/experience/');
    
    return ( 
        <section className="note" id="experience">
            <div>
                <br/><br/><br/>
            </div>

            <p className="fs-1 fw-semibold">Experience</p>
            {dataset && dataset.map((data) => (
                <div className="row mt-5" key={data._id}>
                    {/* 1 */}
                    <div className="col-4">{data.duration}</div>
                    <div className="col-8">
                    <span className="fw-bold">{data.title}</span>
                        <p className="fs-6"><i className="bi bi-buildings"></i> {data.company} &nbsp;<i className="bi bi-geo-alt"></i> {data.companyLocation}</p>
                        <p className="text-wrap">{data.roleContent}</p>
                        {data.techStack.map((item) => (
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2" key={item._id}>{item.techName}</span>
                        ))}
                    </div>
                </div>
            ))}

            {error &&
                <span className="lead">Could not fetch the data for the resource</span>
            }

            {/* <p className="fs-1 fw-semibold">Experience</p>
                <div className="row mt-5">
                    <div className="col-4">2020 - Present</div>
                    <div className="col-8">
                    <span className="fw-bold">Manager, Solution Architect</span>
                        <p className="fs-6"><i className="bi bi-buildings"></i> EXL Service &nbsp;<i className="bi bi-geo-alt"></i> Toronto, CA</p>
                        <p className="text-wrap">Working closely with global financial clients and offshore teams to assemble the client demand and build the dynamic customized solutions.  Leading automations, designing system flow and database model.  Assisting teams resolving technical issues doing research and development for new challenges.</p>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Entity Framework</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">C#</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">.Net</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Python</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">MVC</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">MVVM</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">jQuery</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Javascript</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">SQL Server</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">AutoSys</span>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-4">2019 - 2020</div>
                    <div className="col-8">
                        <span className="fw-bold">Solution Architect</span>
                        <p className="fs-6"><i className="bi bi-buildings"></i> Vyana &nbsp;<i className="bi bi-geo-alt"></i> Toronto, CA</p>
                        <p className="text-wrap">Fullstack engineer expert designing and developing both web and windows based solutions from scratch.  Researching and developing minimum viable products for technology advancement in existting and new application development.  Delivered well engineered, creative and high quality solutions using automated tests.</p>
                        <div>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Entity Framework</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">C#</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">.Net</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Microservice</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Angular</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">React</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Azure</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">CI/CD</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Graph API</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Javascript</span>
                            <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">SQL Server</span>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-4">2017 - 2019</div>
                    <div className="col-8">
                    <span className="fw-bold">Senior System Specialist & Solution Architect</span>
                        <p className="fs-6"><i className="bi bi-buildings"></i> QMA &nbsp;<i className="bi bi-geo-alt"></i> Newark, USA</p>
                        <p className="mt-2">Designed and developed automated jobs, batch scripts for managing complex business processes.  Wrote algorithms and complex formulas to perform calculations on rule-based structure using Telerik grid control.  Led project planning with managers, business analysts, and team members to analyze business requirements and outline system flow and solutions.</p>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">.Net</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">C#</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">MVC</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Python</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">jQuery</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Javascript</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Oracle</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Blue Prism</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">SQL Server</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">AutoSys</span>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-4">2017 - 2017</div>
                    <div className="col-8">
                        <span className="fw-bold">Senior .Net Developer</span>
                        <p className="fs-6"><i className="bi bi-buildings"></i> 1199 SEIU &nbsp;<i className="bi bi-geo-alt"></i> New York, USA</p>
                        <p className="mt-2">Managing existing web applications and monitoring several structured processes to identify flaws and prioritize in the development pipeline.  Test comprehensive features, troubleshooting issues and resolve them for quality assurance.</p>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">.Net</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">C#</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">MVC</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Javascript</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">jQuery</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">SQL Server</span>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-4">2009 - 2017</div>
                    <div className="col-8">
                        <span className="fw-bold">Programmer/Analyst</span>
                        <p className="fs-6"><i className="bi bi-buildings"></i> Arbor Memorial &nbsp;<i className="bi bi-geo-alt"></i> Toronto, CA</p>
                        <p className="mt-2">Worked closely with business analysts and team members to understand business requirements to drive the analysis, quality system design and technical document.  Identify test data management needs and crafting a strategy for assertive standard plus delivery.  Performing peer code reviews, designing web layout, self initiated web service based projects to apply RESTful architecture.</p>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">.Net</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">C#</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Microservice</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">MVC</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">MVVM</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">WPF</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">REST</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Javascript</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">SQL Server</span>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-4">2009 - 2009</div>
                    <div className="col-8">
                    <span className="fw-bold">Web Designer/Developer</span>
                        <p className="fs-6"><i className="bi bi-buildings"></i> AITO &nbsp;<i className="bi bi-geo-alt"></i> Toronto, CA</p>
                        <p className="mt-2">Design web layout using Photoshop and convert into HTML web page for the main AITO public site and for subsequent other projects.  Built website using content management system Drupal, developed drupal themes and modules.</p>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Drupal</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">HTML</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">CSS</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Photoshop</span>
                        <span className="badge rounded-pill bg-success bg-opacity-50 me-2 p-2 mt-2">Flash</span>
                    </div>
                </div> */}

            
        </section>
     );
}
 
export default Experience;